<template>
    <v-dialog v-model="typeDialog" max-width="600px">
        <v-card>
            <v-card-title class="headline"
                style="color:#575962 !important; display: flex; justify-content: space-between; align-items: center;">
                <span>Selecciona tu tipo de tarjeta</span>
                <v-btn icon @click="typeDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6" class="d-flex flex-column align-center">
                            <div :class="['card-option', { disabled: isDigitalEnabled === false }]">
                                <v-icon color="primary" large>mdi-cellphone</v-icon>
                                <h4>Tarjeta Digital</h4>
                                <p>
                                    Con tu Tarjeta de Rebancarización, haz
                                    compras y pagos en línea de forma
                                    segura, sin salir de casa y sin costos
                                    de emisión.
                                    Obtén tu Tarjeta en los próximos 3 días
                                    hábiles y empieza acelerar tu puntaje
                                    crediticio.
                                </p>
                                <v-btn :disabled="isDigitalEnabled === false" color="primary" @click="selectDigital">
                                    {{ isDigitalEnabled === true ? 'Continuar solicitud' : 'Elegir Digital' }}
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex flex-column align-center">
                            <div :class="['card-option', { disabled: isDigitalEnabled === true }]">
                                <v-icon color="secondary" large>mdi-credit-card</v-icon>
                                <h4>Tarjeta Física</h4>
                                <p>
                                    Recibe tu Tarjeta de
                                    Rebancarización en tu domicilio
                                    por un valor de emisión de
                                    $50.000.
                                    Perfecta para todas tus compras
                                    en tiendas físicas y en pasarelas
                                    digitales mientras aceleras tu
                                    puntaje crediticio.
                                </p>
                                <v-btn :disabled="isDigitalEnabled === true" color="secondary" @click="selectPhysical">
                                    {{ isDigitalEnabled === false ? 'Continuar solicitud' : 'Elegir Física' }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
      return {
        typeDialog: this.dialog
      }  
    },
    props: {
        dialog: Boolean,
        product: Object
    },
    computed: {
        isDigitalEnabled() {
            const application = this.product.user_credit_card_application;
            if (application && application.is_digital !== undefined) {
                return application.is_digital;
            }
            return null; // No hay solicitud previa
        }
    },
    methods: {
        selectDigital() {
            this.$emit('select-type', 'digital');
            this.typeDialog = false;
        },
        selectPhysical() {
            this.$emit('select-type', 'physical');
            this.typeDialog = false;
        }
    },
    beforeDestroy(){
        this.typeDialog = false
    },
    watch: {
        'typeDialog'(value){
            this.$emit('dialogValue', value);
        }
    }
}
</script>

<style scoped>
.card-option {
    width: 100%;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #eee;
    border-radius: 8px;
}

.card-option.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.card-option h4 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.card-option p {
    font-size: 14px;
    margin-bottom: 20px;
}

.warning-text {
    color: #f44336;
    /* Red color for warning */
    font-weight: bold;
}

.v-btn {
    color: #575962;
}
</style>
